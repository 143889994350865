import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import {
  DEFAULT_WIDGET_OPTIONS,
  getAspectRatio,
  getBannerImages,
  getButtonVariantsData,
  getHeight,
  getInnerWidth,
  useGetAlignments,
  useUpgradeEditorBannerSelection,
  useWindowResize,
  // StoreName,
  WidgetProps,
  WithLink,
} from './utils';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { twMerge } from 'tailwind-merge';
import CustomizedButton from '../../../WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentButtonAnimationStyle.module.scss';
import { btnActionHandler } from '@/components/DesignSystem/ModernTheme/utils/btnUtils';
import { renderMediaComponent } from '@/components/DesignSystem/ModernTheme/utils/common';
import { BUTTON_SIZE_TYPE } from 'src/components/WidgetMaker/utils/buttonConstant';
import { EditableText } from '../RichTextImage/component/TextEditor';
import { Chevrons } from './components/Chevrons';
import { MAX_MOBILE_WIDTH } from 'src/utils/constants';
import classNames from 'classnames';

export const CarouselWidget = (props: WidgetProps) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();

  const {
    // hasAdminAccess,
    widgetContextState: { widgets, showPreview, globalStyle },
  } = useWidgetDndContextData();
  const isBuilderChevronEnabled = globalStyle?.chevrons;

  const activeWidget = widgets[(props as any).id];
  const activeWidgetProps = activeWidget?.props;

  const widgetOptions = {
    ...DEFAULT_WIDGET_OPTIONS,
    ...props.widgetOptions,
    ...(activeWidgetProps || {}),
  };
  const isNewCarousel = props['forNewCarousel'] || widgetOptions.forNewCarousel;

  const [activeSlide2, setActiveSlide2] = useState(0);
  const [height, setHeight] = useState(getHeight(widgetOptions, '100vh', isNewCarousel));
  const [aspectRatio, setAspectRatio] = useState(widgetOptions?.aspectRatio);
  const [screenWidth, setScreenWidth] = useState(getInnerWidth());
  const isMobile = screenWidth <= MAX_MOBILE_WIDTH;
  const isDesktop = !isMobile;

  useWindowResize({
    dependencies: [showPreview],
    executor: adjustDimensions,
    widgetOptions,
    props,
  });

  const { handleNextClick, handlePrevClick, allSliderSettings } =
    useUpgradeEditorBannerSelection({
      activeSlide2,
      setActiveSlide2,
      id: (props as any).id,
      sliderRef,
      widgetOptions,
      isBuilderChevronEnabled,
    });

  const { getButtonAlignment, getContentPosition } = useGetAlignments({
    isDesktop,
    widgetOptions,
  });

  const images = getBannerImages({ props, activeWidgetProps, isDesktop }) as any[];

  function adjustDimensions() {
    setScreenWidth(getInnerWidth());
    setHeight(getHeight(widgetOptions, containerRef.current?.clientWidth, isNewCarousel));
    setAspectRatio(getAspectRatio(widgetOptions, containerRef.current?.clientWidth));
  }

  if (!images.length) {
    return <div className="tw-h-[80vh] tw-w-[100%]" />;
  }

  return (
    <div
      className={`carousel-widget-slider slider-container tw-relative tw-bg-opacity-20`}
      ref={containerRef}
      id="carousel-widget-slider"
      style={
        isNewCarousel && {
          backgroundColor: '#000',
          marginTop: widgetOptions?.marginTop || '0px',
          marginBottom: widgetOptions?.marginBottom || '0px',
        }
      }
    >
      {isBuilderChevronEnabled && (
        <Chevrons
          widgetOptions={widgetOptions}
          isDesktop={isDesktop}
          currentActiveSlideIndex={activeSlide2}
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
          images={images}
        />
      )}
      <div style={isNewCarousel ? { height: height } : { aspectRatio: aspectRatio }}>
        {!!images.length && (
          <Slider
            // className="carousel-widget-slider"
            {...allSliderSettings}
            ref={(node) => (sliderRef.current = node)}
            className="tw-flex tw-items-center tw-justify-center"
          >
            {images.map((image, index) => {
              const isCurrentSlideActive = activeSlide2 === index;
              const imageUrl = image.image_url || image.src;
              const oldRedirectLink = !isNewCarousel
                ? widgetOptions.links?.[index]?.href
                : '';

              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: '#000',
                  }}
                  className={classNames(
                    `tw-relative  tw-bg-opacity-${image?.textContent?.overlayOpacity || 20}`,
                    (image?.actions?.handler || oldRedirectLink) && 'tw-cursor-pointer'
                  )}
                  onClick={() => {
                    if (image?.actions?.handler) {
                      btnActionHandler(image?.actions);
                    }
                  }}
                >
                  <WithLink {...props} redirectLink={oldRedirectLink} index={index}>
                    <div
                      className={twMerge(
                        'image-container ',
                        'tw-opacity-0 ',
                        isCurrentSlideActive
                          ? contentAnimationStyle.widgetContentFadeInZoomInWrapper
                          : ''
                      )}
                    >
                      {imageUrl ? (
                        image.is_video ? (
                          renderMediaComponent({
                            type: 'video',
                            autoplay: true,
                            hideControls: true,
                            content: [
                              {
                                url: imageUrl,
                              },
                            ],
                          })
                        ) : (
                          <img
                            className="tw-w-full tw-object-cover"
                            src={imageUrl}
                            alt={image?.textContent?.bannerMetaText || ''}
                          />
                        )
                      ) : (
                        <img
                          src={'/assets/images/Hero_default.jpg'}
                          alt={image?.textContent?.bannerMetaText || ''}
                          className="tw-w-full tw-object-cover"
                        />
                      )}
                    </div>
                  </WithLink>
                  <div
                    className={`tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1]`}
                    style={{
                      background: `rgba(0,0,0, ${image?.textContent?.overlayOpacity / 100})`,
                    }}
                  ></div>
                  <div
                    style={{
                      width: '100%',
                      justifyContent: !isMobile
                        ? widgetOptions?.contentPositionDesktop
                        : 'center',
                    }}
                    className="tw-absolute tw-top-1/2 tw-z-30 tw-flex tw-min-w-[30%] tw--translate-y-1/2"
                  >
                    <div
                      className={twMerge(
                        ` ${!isMobile ? 'tw-mx-[80px] tw-p-[52px]' : 'tw-px-[24px] tw-py-[40px]'}`,
                        isCurrentSlideActive &&
                          contentAnimationStyle[
                            widgetOptions.contentAnimationConfig?.className
                          ]
                      )}
                      style={{
                        width: !isMobile
                          ? `${image?.textContent?.width}%` || '30%'
                          : '90%',
                        color: image?.textContent?.textColor || '#ffffff',
                        background: isCurrentSlideActive
                          ? image?.textContent?.backgroundColor
                          : 'transparent',
                      }}
                    >
                      {image?.textContent?.isShow && isCurrentSlideActive && (
                        <div className={twMerge('tw-z-[999]')}>
                          {image?.textContent?.headline ? (
                            <EditableText
                              style={{
                                textAlign: getContentPosition(),
                                fontSize: isDesktop ? 40 : 28,
                              }}
                              content={image?.textContent?.headline}
                              className={`tw-my-0 tw-font-light tw-text-${getContentPosition()} tw-text-[28px] md:tw-text-[40px] `}
                            ></EditableText>
                          ) : null}
                          {image?.textContent?.description ? (
                            <EditableText
                              content={image?.textContent?.description}
                              style={{
                                textAlign: getContentPosition(),
                              }}
                              className={`tw-mb-0 tw-mt-16 tw-text-16 tw-font-light tw-leading-[23px] tw-text-${getContentPosition()}`}
                            ></EditableText>
                          ) : null}
                        </div>
                      )}
                      {image?.buttons?.length && isCurrentSlideActive ? (
                        <div
                          className={twMerge(
                            `tw-mt-[36px] tw-flex tw-w-full tw-flex-col md:tw-flex-row ${getButtonAlignment('flex')}`,
                            isCurrentSlideActive &&
                              buttonAnimationStyle[
                                widgetOptions.contentAnimationConfig?.className
                              ]
                          )}
                          style={{
                            flexDirection: isDesktop ? 'row' : 'column',
                          }}
                        >
                          {image.buttons?.map((buttonData, btnIndex) => {
                            const buttonVariant = getButtonVariantsData(buttonData);
                            const selectedButtonType = buttonVariant[buttonData.type];

                            return buttonData.isShow ? (
                              <CustomizedButton
                                key={btnIndex}
                                size={BUTTON_SIZE_TYPE.LARGE}
                                btnType={buttonData.type}
                                buttonData={buttonData}
                                selectedButtonData={selectedButtonType}
                                variant={selectedButtonType.variant}
                                textualAlignment={getContentPosition()}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  btnActionHandler(buttonData?.actions, () => {});
                                }}
                                className={twMerge(
                                  `tw-rounded-none !tw-text-[16px] !tw-font-normal
                               [&>p]:!tw-text-ellipsis [&>p]:!tw-whitespace-nowrap
                                ${
                                  btnIndex
                                    ? isDesktop
                                      ? ' !tw-ml-[25px] sm:!tw-ml-0 sm:!tw-mt-[25px] '
                                      : ' !tw-mt-[14px] sm:!tw-ml-0 sm:!tw-mt-[14px] '
                                    : ''
                                }
                                ${!isDesktop ? ' !tw-w-[100%]' : ''}
                                `
                                )}
                              >
                                {buttonData?.text}
                              </CustomizedButton>
                            ) : null;
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default CarouselWidget;
